import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Metcon Week`}</em></p>
    <p><strong parentName="p">{`“Scottie”`}</strong></p>
    <p>{`10 rounds for time of:`}</p>
    <p>{`10 Meter Farmers Carry (33ft) (70’s/50’s)`}</p>
    <p>{`5 Strict Chin Ups`}</p>
    <p>{`10 Meter Farmers Carry`}</p>
    <p>{`5 Burpees`}</p>
    <p>{`5 Deadlifts (265/185)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      